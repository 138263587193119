import React from "react";
import ReactDOM from "react-dom";
import styled from 'styled-components';
import includes from "lodash/includes";

const ContainerDiv = styled.div`
    position: sticky;
    bottom: 0
    background-color: black;
`;

const Carat = styled.div`
  color: lightgreen;
  display: inline;
  margin-right:4px;
`;

const InputField = styled.input`
  outline: 0;
  margin: 0;
  width:95%;
  padding: 0;
  background-color: black;
  color: white;
  border: none;
  font-family: "Courier New", Courier, monospace;
  cursor: default;
`;

class Input extends React.Component {
    constructor(props){
        super(props);
        this.inputRef = React.createRef();
    }
    componentDidMount(){
        window.addEventListener("keydown", () => this.focusCheck() )
    }

    focusCheck(){
        if( !this.props.hiddenInput && document.activeElement !== ReactDOM.findDOMNode(this.refs.inputRef) )
            this.inputRef.focus()
    }

    checkInput(e){
        this.props.setUserInput(e.target.value)
    }

    onEnter(v){
        if(v.key === "Enter"){
            v.preventDefault();

            this.props.commitUserInput()
            const userInputs = this.props.userInput.split(" ");
            if( userInputs.length && includes(this.props.currentCommands, userInputs[0])){
                this.props.initChapterCommand(userInputs[0])
            }
        }
    }

    render(){
        return (
            <ContainerDiv style={this.props.hiddenInput ? {display: "none"} : {}}>
                <Carat>»</Carat>
                <InputField ref={(input) => {this.inputRef = input}} onKeyPress={(v) => this.onEnter(v)} value={this.props.userInput} onChange={(e) => this.checkInput(e)}/>
            </ContainerDiv>
        );
    }
}

export default Input;

import React from 'react';
import ChapterOne from "../chapters/1";
import { Objects } from "../chapters/defaults.js";

const Chapters = {
    1: ChapterOne
}

export const AppContext = React.createContext({
    currentChapter: 1,
    currentNode: 0,
    currentCommands: ['new', 'load', 'exit'],
    textList: [Chapters[1][0].text()],
    userInput: ""});

class AppProvider extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            currentChapter: 1,
            currentNode: 0,
            currentCommands: ['new'],
            textList: [Chapters[1][0].text()],
            userInput: "",
            hiddenInput: false,
            setUserInput: (v) => this.setUserInput(v),
            commitUserInput: () => this.commitUserInput(),
            setCurrentNode: (v) => this.setCurrentNode(v),
            initChapterText: (v) => this.initChapterText(v),
            initChapterCommand: (v) => this.initChapterCommand(v),
            getCurrentNode: () => this.state.currentNode,
            toggleInput: (v) => this.setState({hiddenInput: v}),
            getObjectNode: (v) => this.getObjectNode(v),
            addToTextList: (v) => this.addToTextList(v)
        }
    }

    setCurrentNode(v){
        const newNode = Chapters[this.state.currentChapter][v];
        this.setState((state) => ({
            ...state,
            currentNode: v,
            currentCommands: Object.keys(newNode.commands)
        }))
    }

    getChapterNode(v){
        return Chapters[this.state.currentChapter][v]
    }

    getObjectNode(v){
        return Objects[v]
    }

    addToTextList(v){
        this.setState((state) => ({
            ...state,
            textList: [...state.textList, v]
        }))
    }

    initChapterText(v){
        this.addToTextList(this.getChapterNode(v).text());
    }

    initChapterCommand(c){
        const currentChap = this.getChapterNode(this.state.currentNode);
        currentChap.commands[c](this.state);
    }

    commitUserInput(){
        this.setState((state) => ({
            ...state,
            textList: [ ...state.textList, <div>{state.userInput}</div> ],
            userInput: ""
        }))

    }

    setUserInput(v){
        this.setState({userInput: v})
    }

    render() {
        return <AppContext.Provider value={this.state}>{this.props.children}</AppContext.Provider>;
    }
}



export default AppProvider;
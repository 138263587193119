import React from "react";

class CommandWindow extends React.Component {
    render(){
        return (
            <div style={{padding:"10px"}}>
                {this.props.textList}
            </div>
        );
    }
}

export default CommandWindow;
import React from "react";
import Typist from "react-typist";
import styled from 'styled-components';
import {AppContext} from "../../context/AppContext";

const TextDiv = styled.div`
  white-space: pre-line;
  padding: 5px;
`;

const OutputDiv = styled.div`
    overflow: hidden;
    white-space: initial;
    padding: 5px;
    &:focus{
        outline-color: transparent;
    }

`;

const RemoveFocus = styled.div`
&:focus{
        outline-color: transparent;
    }
`

// for other devs who might not know keyCodes
var ENTER_KEY = 13;

class IncrementalText extends React.Component {
    constructor(props){
        super(props);
        this.state = { finished: false };
        this.contentDiv = React.createRef();
    }

    componentDidMount(){
        this.contentDiv.current.focus();
    }

    _handleKeyDown(event, myProps){
        if( !this.state.finished )
            switch( event.keyCode ) {
                case ENTER_KEY:
                    this.setState({ finished: true });
                    this.onTypistFinish( myProps );
                    break;
                default:
                    break;
            }
    }

    onTypistFinish(props){
        if( this.props.nextNode )
            this.nextNode(props);
        else
            props.toggleInput(false);
    }

    nextNode({setCurrentNode, getCurrentNode, initChapterText}){
        this.setState({finished: true});
        let nodeID = this.props.nodeID;
        window.addEventListener("keydown", () => {
            if( nodeID === getCurrentNode()){
                setCurrentNode( nodeID + 1 )
                initChapterText( nodeID + 1 )
            }

        } )
    }

    renderTypist(myProps){
        return  <Typist avgTypingDelay={0} onTypingDone={() => this.onTypistFinish(myProps)} cursor={{show: false}}>
            <TextDiv>
                {this.props.children}
            </TextDiv>
        </Typist>
    }

    render(){
        return (
            <AppContext.Consumer>{
                (myProps) =>
                    <RemoveFocus tabIndex="0" ref={this.contentDiv} onKeyDown={ (event) => this._handleKeyDown(event, myProps)}>
                        {this.state.finished ? <OutputDiv>{this.props.children}</OutputDiv> : this.renderTypist(myProps)}
                        {this.state.finished && myProps.getCurrentNode() === this.props.nodeID && <div>Press any button to continue...</div>}
                    </RemoveFocus>
            }
            </AppContext.Consumer>
        );
    }
}

export default IncrementalText;
import React from "react";
import IncrementalText from "../components/incrementalText";
import CharacterText from "../components/characterText";

export const VERBS = {
    1: "examine"
}

export const defaultCommands = {
    examine: (state) => {
        const {userInput, getObjectNode, addToTextList} = state;
        const myObject = userInput
                            .toLowerCase()
                            .replace("examine ", "")
                            .replace(" ", "_");
        //todo: insert logic to remove dumb words like "the" "a" etc...
        var node = getObjectNode(myObject);
        addToTextList(node.text(1, state))
    }
}

//if something is multiple words, concat with _
export const Objects = {
    felix: { text: () => (
        <IncrementalText nodeID={"felix"} nextNode={false}>
            <CharacterText character="FELIX" emotion="NEUTRAL">"I'm jus' a fella, I guess."</CharacterText>
            As he says, just a normal fella, standing at a not-so-tall 5'0" exactly.  Bright blonde hair and a messy orbit of a man, maybe late twenties.  A salt of the 'Earth' type, Felix grew up on [PHOBOS] alongside his sister, [PAULA].
        </IncrementalText>) },
    gilese: { text: () => (
        <IncrementalText nodeID={"gilese"} nextNode={false}>
            An old ship.  Something of an antique given it was first constructed during the [MARS SURFACE WAR].  Originally used as a simple transport ship, it's been refitted multiple times and now is used as a junker for a simple scavenger crew.  It has its negatives to its age - such as missing many security upgrades (don't expect anything further than a simple numerical lock for your cabin room), but positives abound.  Such as the noise the exhaust fans make and the feeling that certainly there's a xenomorph aboard.
        </IncrementalText>
    ) },
    mars: { text: () => (
        <IncrementalText nodeID={"mars"} nextNode={false}>
            Not exactly home.  The big red planet was colonized maybe a good four dozen years ago by a group of Russian oligarchs.  It's gone back and forth between being property of the [USSR] and having its own independence, now led by the [MARS INDEPENDENTS].  The numerous set of battles from the drama that is the planet leaves quite a mess in orbit for folks in the [GILESE] to pick up, after all.
        </IncrementalText>
    ) },
    deimos: { text: () => (
        <IncrementalText nodeID={"deimos"} nextNode={false}>
            One of the moons of [MARS].  After the last few skirmishes 20 years ago or so, it's been left with quite a mess in its atmosphere, leaving it hard difficult for the settlers there.  Many ended up leaving for Mars itself, but a few still keep around, mostly hanging about the port cities, kept to the safety of buildings that weren't burned or bombed.  During the many wars it was used as a base for the [USSR], leaving many of the settlers there still loyal to that faction.  Some seem to claim that the only good time for them was [i]during[/i] the war.
        </IncrementalText>
    )} ,
    phobos: { text: () => (
        <IncrementalText nodeID={"phobos"} nextNode={false}>
            <CharacterText character="FELIX" emotion="SAD">"Home...where Polly is."</CharacterText>
            Phobos, one of the moons of [MARS].  If there were any windows on the ship of the [GILESE], you wouldn't be able to see it from here.  It's at least more peaceful than its sister moon, a breadbasket of the [MARS INDEPENDENTS].  First settled by pilgrims that saught to spread their teachings of peace to a new sky, Phobos now is mostly farmers and cattle ranchers, fully terraformed through both luck and a good amount of money thrown at it by the Independents.  [PAULA] lives there, tending the family farm.
        </IncrementalText>
    )},
    fridge: { text: () => {} },
    mars_surface_war: { text: () => {} },
    mars_independents: { text: () => {} },
    socket: { text: () => {} },
    paula: { text: () => {} }
}
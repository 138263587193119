import React from 'react'
import styled from 'styled-components';
import CommandWindow from './components/commandWindow';
import OutputWindow from './components/outputWindow';
import Input from './components/input'
import AppProvider, {AppContext} from "./context/AppContext";


const Container = styled.div`
  font-family: "Courier New", Courier, monospace;
  white-space: pre;
  background-color: black;
  color: white;
  width: 100%;
  position: relative;
  height: 100vh;
  overflow-y:auto;
`;

class App extends React.Component {
  render(){
      return (
          <AppProvider>
              <Container>
                  <CommandWindow />
                  <AppContext.Consumer>{ (myProps) => <OutputWindow {...myProps} />}</AppContext.Consumer>
                  <AppContext.Consumer>{ (myProps) => <Input {...myProps} />}</AppContext.Consumer>
              </Container>
          </AppProvider>
      );
  }
}

export default App;

import React from 'react'
import styled from 'styled-components';

const Title = styled.div`
  font-size: 14px;
`;

const TitlePre = styled.pre`
  margin: 0
`

class titleCard extends React.Component{
  render(){
      return (
        <Title>
            <TitlePre>._._ _ o_ __|_  _._ o _|_._ o</TitlePre>
            <TitlePre>|_| (_)(/(_ |_ (/|  |(_(_| ||</TitlePre>
            <TitlePre>|     _|</TitlePre>
            <div>by Tande</div>
            <div>System is still loading. . .Finished!</div>
        </Title>
    );
    }
}

export default titleCard;

import React from "react";
import Title from "../../components/title";
import {defaultCommands} from "../defaults";
import IncrementalText from "../../components/incrementalText";
import CharacterText from "../../components/characterText";

const ChapterOne = [
    {
        text: () => ( <Title /> ),
        commands: { new: ({setCurrentNode, initChapterText, toggleInput}) => { setCurrentNode(1); initChapterText(1); toggleInput(true) } }
    },
    {
        text: () =>
                    <IncrementalText nodeID={1} nextNode={true}>
                        S'been a long time since we left Earth, my friends.  Not sayin' its not there no more, nah, it's just...well, not exactly the best kinda place ta be.  No matt'a the trouble t'at its all been, we've seen a lot more t'an Earth, I tell ya.  We've colonized Mars, got through t'e ol' asteroid belt, threw a few colonies on Jupiter's moons for funsies, the like.
                    </IncrementalText>,
        commands: {}
    },
    {
        text: () =>
                    <IncrementalText nodeID={2} nextNode={true}>
                        But the whole...uh, space thing.  Well, space bein' a large...bit'o'space.  T'at has made a trifle trouble.  Sure, most of the colonies got t'eir governments and what-have-ya, but out inta the far reaches of space?  Ain't nobody here to hear ya scream for t'e most part.  But t'at is all fine and dandy, s'pecially for t'e folks who like it t'at way.
                    </IncrementalText>,
        commands: {}
    },
    {
        text: () =>
                    <IncrementalText nodeID={3} nextNode={true}>
                        Anyway, us folks, we like ta call ourselves outlanders.  We make a livin' doing what needs ta be done, pickin' through t'e mess that people left behind, whether it be from wars or t'e people t'at just <i>came before us</i>.  N'sometimes...eh, well, sometimes ya find yaself a beaut, a motha'load, a cache'o'glory!
                    </IncrementalText>,
        commands: {}
    },
    {
        text: () =>
                    <IncrementalText nodeID={4} nextNode={true}>
                        Tat's glory alright.  Until well, t'e wrong people hear bout it.  N'its not just ot'er outlanders ya gotta worry 'bout...
                    </IncrementalText>,
        commands: {}
    },
    {
        text: () => <IncrementalText nodeID={5} nextNode={true}>
                        <CharacterText character="FELIX" emotion="FRUSTRATED">"Ugh, do they think I'm some sorta janitor or somethin'?"</CharacterText>
                    </IncrementalText>,
        commands: {}
    },
    {
        text: () => <IncrementalText node={6}>
                        [FELIX] is standing in the loading bay of the [GILESE], which hovers above [MARS]'s moon, [DEIMOS].  He stares rather angrily towards what one could only call the worst sort of looking [FRIDGE], speckled with dents and dings and humming loudly, still plugged into a nearby [SOCKET].
                    </IncrementalText>,
        commands: defaultCommands
    }
];

export default ChapterOne;
import React from "react";
import styled from "styled-components";

import FELIX_BLUSH from './images/FELIX_BLUSH.png';
import FELIX_DEPRESSED from './images/FELIX_DEPRESSED.png';
import FELIX_FRUSTRATED from './images/yq/angery-filly2.png';
import FELIX_GLEE from './images/FELIX_GLEE.png';
import FELIX_HAPPY from './images/FELIX_HAPPY.png';
import FELIX_NEUTRAL from './images/yq/normal-filly.png';
import FELIX_SAD from './images/yq/sad-filly.png';
import FELIX_SMILE from './images/FELIX_SMILE.png';
import FELIX_SURPRISED from './images/FELIX_SURPRISED.png';

const CharacterBox = styled.div`
  border: 1px white solid;
  display: flex;
`;

const TextBox = styled.div`
  border: 1px white solid;
  padding: 5px;
  width: 100%;
  margin: 5px;
`


const images = {
    FELIX: {
        BLUSH: FELIX_BLUSH,
        DEPRESSED: FELIX_DEPRESSED,
        FRUSTRATED: FELIX_FRUSTRATED,
        GLEE: FELIX_GLEE,
        HAPPY: FELIX_HAPPY,
        NEUTRAL: FELIX_NEUTRAL,
        SAD: FELIX_SAD,
        SMILE: FELIX_SMILE,
        SURPRISED: FELIX_SURPRISED
    }
}

class CharacterText extends React.Component {
    render(){

            const { character, emotion } = this.props;
        return (
            <CharacterBox>
                <div>
                    <img width="125px" alt="" src={images[character][emotion]} />
                </div>
                <TextBox>
                {this.props.children}
                </TextBox>
            </CharacterBox>
        );
    }
}

export default CharacterText;
import React from "react";
import styled from 'styled-components';

import {AppContext} from "../../context/AppContext";

const ContainerWindow = styled.div`
  border: 1px white solid;
  position: fixed;
  right: 15px;
  background-color:black;

`;

const WindowHeader = styled.div`
  width: 100%;
  background-color:white;
  color: black;
  cursor: pointer;
`;


class CommandWindow extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            isOpen: true
        }
    }

    renderAvailableCommands(){
        return <AppContext.Consumer>{
            ({currentCommands,setUserInput, userInput}) => (currentCommands.map( (e) => <div style={{cursor:"pointer"}} key={e} onClick={ () => setUserInput(userInput + e)} >{e}</div>))
        }
        </AppContext.Consumer>
    }

    render(){
        return (
            <ContainerWindow>
                <WindowHeader onClick={() => this.setState({isOpen: !this.state.isOpen})}>{this.state.isOpen ? "» Available" : "«"} Commands</WindowHeader>
                { this.state.isOpen && this.renderAvailableCommands()}
            </ContainerWindow>
        );
    }
}

export default CommandWindow;